<template>
  <v-app>
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-3">
              <div class="text-center">
                <v-avatar size="100" color="grey" tile class="mt-n12">
                  <v-img
                    src="https://cdn.vuetifyjs.com/images/john.jpg"
                    alt="Sistesi"
                  />
                </v-avatar>
              </div>
              <v-card-title class="justify-center align-center headline mb-n3">
                E-Test
              </v-card-title>
              <div class="text-center subtitle-2 font-weight-regular">
                Silahkan Login untuk melanjutkan
              </div>
              <v-card-text>
                <v-col cols="12" sm="12" md="12">
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-text-field
                      v-model="data.username"
                      label="Username"
                      name="username"
                      prepend-icon="mdi-account"
                      type="text"
                      dense
                      outlined
                      :rules="rules.usernameRules"
                    />

                    <v-text-field
                      v-model="data.password"
                      prepend-icon="mdi-lock"
                      :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[
                        rules.passwordRules.required,
                        rules.passwordRules.min
                      ]"
                      :type="showPwd ? 'text' : 'password'"
                      name="password"
                      label="Password"
                      dense
                      outlined
                      @click:append="showPwd = !showPwd"
                    ></v-text-field>
                  </v-form>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <v-btn
                    color="primary"
                    block
                    :disabled="!valid"
                    @click="validate"
                    >Login</v-btn
                  >
                </v-col>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      valid: true,
      showPwd: false,
      data: {
        username: "",
        password: ""
      },
      rules: {
        usernameRules: [value => !!value || "Name is required"],
        passwordRules: {
          required: value => !!value || "Password is required.",
          min: v => v.length >= 6 || "Min 6 characters"
        }
      }
    };
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch("Login", this.data);
      }
    }
  }
};
</script>
